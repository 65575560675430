@font-face {
  font-family: "Inter500";
  src: url("assets/fonts/Inter-Medium.woff2") format("woff2"),
    url("assets/fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter700";
  src: url("assets/fonts/Inter-Bold.woff2") format("woff2"),
    url("assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter800";
  src: url("assets/fonts/Inter-ExtraBold.woff2") format("woff2"),
    url("assets/fonts/Inter-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter400";
  src: url("assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter600";
  src: url("assets/fonts/Inter-SemiBold.woff2") format("woff2"),
    url("assets/fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Inter400", "open sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  color: #18191f;
  background: #f7f8fa;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.MuiMenuItem-root {
  font-family: "Inter400", "open sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
}

.MuiBackdrop-root {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.slick-dots li button:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: black;
}

.slick-dots li.slick-active button:before {
  background-color: #2280c1;
  opacity: 1;
}
.slick-dots li,
.slick-dots li button {
  width: auto;
  height: auto;
}
.slick-prev,
.slick-next {
  display: none !important;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.glow {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
  color: orange;
  font-weight: bold;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: #000 !important;
  font-family: "Inter400" !important;
}

.MuiFormLabel-root {
  font-family: "Inter400" !important;
}

.MuiDialog-paperWidthLg {
  width: 100%;
}

.login-dialog .MuiDialog-paperWidthLg,
.sign-up-dialog .MuiDialog-paperWidthLg {
  width: 1200px;
}

/*
* driver.js
*/

.driver-popover {
  max-width: fit-content;

  inset: unset !important;
  top: 10px !important;
  right: 10px !important;

  .driver-popover-arrow {
    display: none !important;
  }
}

.driver-custom-popover-class .driver-popover-description {
  font-size: 18px;

  .ai-assist-name {
    font-size: 20px;
  }
}

.driver-popover-footer .driver-popover-navigation-btns {
  button {
    background: black;
    color: white;
    text-shadow: none;
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px;
  }
}

/**3d model  */

.hide {
  display: none;
}

.material,
.text {
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  border: 1px solid #ccc;
  color: #262626;
}
.material:hover,
.text:hover {
  cursor: pointer;
}

.selected {
  background-color: #2495ff;
  color: #fff;
}

.title {
  font-size: 16px;
  padding-bottom: 4px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.config {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

#model {
  /* canvas {
    max-width: 80vw !important;
    height: auto !important;
    margin: 0 auto;
    display: block;
  } */
}

/** 3d model */
